<template>
  <b-overlay
      :show="categories === null"
      rounded="sm"
  >
    <div v-if="categories !== null">

      <!-- Table Container Card -->
      <b-card
          class="mb-0"
          no-body
      >

        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <b-col
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                cols="12"
                lg="3"
            >
              <b-link
                  to="/pages/blog/categories/add"
                  variant="primary"
              >
                <span class="text-nowrap">+ ساخت دسته بندی جدید</span>
              </b-link>
            </b-col>
            <b-col cols="12" lg="2" class="ml-auto">
              <clear-sorts-btn @clear="sortBy=''"></clear-sorts-btn>
            </b-col>
          </b-row>

        </div>

        <b-table
            :sort-by.sync="sortBy"
            ref="refUserListTable"
            :fields="myTableColumns"
            :items="categories"
            bordered
            class="position-relative"
            empty-text="موردی موجود نیست!"
            primary-key="id"
            responsive
            show-empty
            striped
        >
          <template #cell(type)="data">

            <span>{{ data.item.type === 1 ? 'بلاگ' : 'مجله' }}</span>

          </template>

          <!-- Column: delete -->

          <template #cell(operation)="data">
            <div class="d-flex align-items-center justify-content-between">

              <div v-b-modal.modal-delete
                   class="cursor-pointer d-flex flex-row"
                   @click="showModal(data.item.blogCategoryId)"
              >
                <feather-icon class="text-danger" icon="TrashIcon" size="20"/>
              </div>
              <router-link v-if="data.item.type===2"
                           :to="{ name: 'pages-blog-categories-priorities', params: { categorySeourl: data.item.seourl ,id: data.item.blogCategoryId} }"
                           class="cursor-pointer text-secondary d-flex flex-row">
                <feather-icon icon="SettingsIcon" size="20"/>
              </router-link>
              <router-link :to="{ name: 'pages-blog-categories-edit', params: { id: data.item.blogCategoryId } }"
                           class="cursor-pointer d-flex flex-row">
                <feather-icon icon="EditIcon" size="20"/>
              </router-link>
            </div>
          </template>


        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
                cols="12"
                sm="6"
            >
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
    <b-modal
        id="modal-delete"
        cancelTitle="انصراف"
        centered
        ok-title="حذف"
        @ok="deleteCategory(deleteItem)"
    >
      <span>حذف شود؟</span>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay, BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {BlogCategoryGetAllRequest} from "@/libs/Api/Blog";
import {BlogCategoryCreateRequest} from "@/libs/Api/Blog";
import {BlogCategoryDeleteRequest} from "@/libs/Api/Blog";
import ClearSortsBtn from "@/views/components/clearSortsBtn.vue";

export default {
  name: "categories-list",
  title: "دسته بندی های بلاگ",
  data() {
    return {
      categories: null,
      totalCount: null,
      addNewCategory: false,
      deleteItem: null,
      sortBy: '',
      currentPage: 1,
      perPage: 10,
      myTableColumns: [
        {key: 'blogCategoryId', label: 'شناسه', sortable: true},
        {key: 'name', label: 'نام', sortable: true},
        {key: 'type', label: 'نوع', sortable: true},
        {key: 'operation', label: 'عملیات'},
      ],
      defaultCreateData: {
        isDeleted: false,
        blogCategoryId: 0,
        name: ''
      },
    }
  },
  async created() {
    await this.getBlogCategories()
  },
  components: {
    ClearSortsBtn,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,

    vSelect,
  },
  methods: {
    async getBlogCategories() {
      let _this = this;

      let blogCategoryGetAllRequest = new BlogCategoryGetAllRequest(_this);
      await blogCategoryGetAllRequest.fetch(function (content) {
        _this.categories = content.blogCategories;
      }, function (content) {

      });
    },
    async deleteCategory(param) {
      let _this = this;

      let blogCategoryDeleteRequest = new BlogCategoryDeleteRequest(_this);
      blogCategoryDeleteRequest.setId(param);
      await blogCategoryDeleteRequest.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `دسته بندی جدید حذف شد.`,
          },
        })
        _this.getBlogCategories()
      }, function (error) {
        console.log(error)
      })
    },
    showModal(param) {
      let _this = this;
      _this.deleteItem = param
    },
  },
}
</script>

<style scoped>

</style>
